<template>
  <div id="stage-container">
    <!-- 图层填充缩略图 -->
    <canvas id="fill_canvas" width="250" height="150" style="display: none"></canvas>
    <img id="fill_img" width="250" height="150" style="display: none" />
    <img id="fill_img2" width="25" height="15" style="display: none" />
    <el-upload name="gds" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/"
      :on-change="uploadFile" :auto-upload="false">

      <el-button size="small" type="primary">上传版图文件</el-button>
    </el-upload>
    <el-dialog :title="$t('messages.tips')" :visible.sync="dialogVisible" width="30%">
      <el-checkbox v-model="mergeGraphics">合并几何数据</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox v-model="generateFill">生成多边形填充</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox v-model="labelAbs">标签绝对显示</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox v-model="antFxaa">FXAA抗锯齿</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox v-model="antSmaa">SMAA抗锯齿</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-select v-model="cellName" :placeholder="$t('messages.placeholder_0')">
        <el-option v-for="item in cellNames" :key="item" :label="item" :value="item"></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('messages.cancel') }}</el-button>
        <el-button type="primary" @click="
            dialogVisible = false
            renderCell()
          ">
          {{ $t('messages.sure') }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 画板 -->
    <div id="box-container" class="box-container">
      <div class="ruler-x" v-if="currentLayoutReactive">
        <canvas id="fileIdcellIdruler-x" :width="currentLayoutReactive.rulerX.width"
          :height="currentLayoutReactive.rulerX.height"></canvas>
      </div>
      <div class="ruler-y" v-if="currentLayoutReactive">
        <canvas id="fileIdcellIdruler-y" :width="currentLayoutReactive.rulerY.width"
          :height="currentLayoutReactive.rulerY.height"></canvas>
      </div>

      <div id="canvas_container" class="axis">
        <canvas id="fileIdcellId_canvas" class="axis" @contextmenu.prevent="onContextmenu()"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/iconfont/iconfont.css";
import { QedaCellLayout } from "@/utils/graphic_cell/qeda-cell-layout";
import bus from "@/components/common/bus";
import { expand_repetition } from "./fileList/gdstk_filter.js";
import { initTextGeo } from "@/utils/graphic_cell/utils/font-util";
export default {
  data() {
    this.currentLayout = null;
    return {
      dialogVisible: false,
      mergeGraphics: false,
      generateFill: true,
      labelAbs: true,
      antFxaa: false,
      antSmaa: false,
      cellName: "",
      cellNames: [],
      cellId: "",
      cells: [],
      tabs: [],
      fileList: [],
      currentLayoutReactive: null,
      timer: null,
    };
  },
  mounted() {
    initTextGeo();

    let fileInfo = { fileId: "fileId" };
    let cellInfo = { cellId: "cellId" };
    let containerId = fileInfo.fileId + cellInfo.cellId;
    let layout_temp = {
      fileInfo,
      cellInfo,
      containerId,
    };
    this.updateCurrentLayoutReactive(layout_temp);
    setTimeout(() => {
      this.currentLayout = new QedaCellLayout(
        {},
        {},
        fileInfo,
        cellInfo,
        new Kernel.Cell("test"),
        null
      );
      this.updateCurrentLayoutReactive(this.currentLayout);
    });

    window.addEventListener("resize", () => {
      if (!this.currentLayout) return;
      var canvas = document.getElementById(this.currentLayout.canvasId);
      if (!canvas) return;
      bus.$emit("resizeLayer", true);
      this.updateCurrentLayoutReactive(this.currentLayout);
    });
  },
  destroyed() {
    this.fileList = [];
    this.currentLayout = null;
  },
  methods: {
    test() {
      this.currentLayout.active();
    },
    onContextmenu() {},
    updateCurrentLayoutReactive(layout) {
      let rx_w = layout.AXIS?.rulerX.width;
      let rx_h = layout.AXIS?.rulerX.height;

      let ry_w = layout.AXIS?.rulerY.width;
      let ry_h = layout.AXIS?.rulerY.height;
      this.currentLayoutReactive = {
        rulerX: {
          width: rx_w,
          height: rx_h,
        },
        rulerY: {
          width: ry_w,
          height: ry_h,
        },
      };
    },
    renderCell() {
      this.currentLayout.STAGE.config.useMergeBufferGeometries =
        this.mergeGraphics;
      this.currentLayout.STAGE.config.showFill = this.generateFill;
      this.currentLayout.STAGE.config.renderConfig.labelAbsoulate =
        this.labelAbs;
      this.currentLayout.STAGE.config.antialias.useSmaaPass = this.antSmaa;
      this.currentLayout.STAGE.config.antialias.useFxaaPass = this.antFxaa;
      this.currentLayout.setFileAndCell(this.Qlib, this.cellName);
    },
    //上传文件
    uploadFile(file) {
      var event = event || window.event;
      var file = event.target.files[0];

      console.time("加载文件");
      var reader = new FileReader();
      reader.readAsArrayBuffer(file);
      let that = this;
      reader.onload = function (e) {
        const blob = new Uint8Array(reader.result);

        let id = "open_cell";
        window.QGdstk.FS.writeFile(id, blob);
        that.Qlib = window.QGdstk.read_gds(id);
        // expand_repetition(that.Qlib);

        that.cellNames = that.Qlib.cells.map((c) => c.name);
        that.cellName = that.cellNames[0];
        that.dialogVisible = true;

        console.timeEnd("加载文件");
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.box-container {
  display: grid;
  grid-template-columns: 22px 1fr;
  grid-template-rows: 22px 1fr;
  padding: 0px 0px 0px 0px;
  height: 100%;
}

.ruler-x {
  grid-row-start: 1;
  grid-column-start: 2;
  // background-color: blue;
  // border:1px solid #F00;
}
.ruler-y {
  grid-row-start: 2;
  grid-column-start: 1;
  // border:1px solid #F00;
  // background-color: blueviolet;
  /* grid-row-end: 3; */
}
.test {
  width: 300px;
  height: 300px;
  grid-row-start: 2;
  grid-column-start: 2;
  border: 1px solid #f00;
}
.axis {
  // width: 850;
  // height: 850;
  // margin: 100px;
  /* grid-row-start: 2;
    grid-column-start: 2; */
  grid-row-start: 2;
  grid-column-start: 2;
  background-color: black;
  // border:1px solid #F00;
  /* grid-row-end: 3; */
}

.layer {
  width: 30px;
  height: 20px;
  background-color: #f00;
  border: 1px solid #f00;
  display: inline-block;
}
#stage-container {
  height: 100%;
}
// canvas[data-cursor="select"] {
//   cursor: default; //url("/data/assets/整体选择.svg") 6 2, auto;
// }
// canvas[data-cursor="move"] {
//   cursor: move;
// }

.textInputBox {
  position: fixed;
  z-index: 999;
  box-shadow: 0 0 10px 5px #666;
}
.upload-demo {
  position: fixed;
  right: 0;
}
</style>
